import { graphql, useStaticQuery } from 'gatsby';
import { useEffect, useState } from 'react';

const globalWindow = typeof window !== 'undefined' ? window : undefined;

export default function usePrimaryContact() {
  const [isAds, setIsAds] = useState(false);

  useEffect(() => {
    if (localStorage?.getItem('fromAds')) {
      setIsAds(true);
    }
  }, [globalWindow?.localStorage?.getItem('fromAds')]);

  const {
    strapiBaseSetting: { primary_contact, ads_contact },
  } = useStaticQuery(graphql`
    query {
      strapiBaseSetting {
        primary_contact {
          full_name
          position
          calendly_url
          subtitle
          avatar {
            childImageSharp {
              fluid(quality: 100, maxWidth: 200) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        ads_contact {
          full_name
          position
          calendly_url
          subtitle
          avatar {
            childImageSharp {
              fluid(quality: 100, maxWidth: 200) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);

  return isAds ? ads_contact : primary_contact;
}
