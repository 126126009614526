import * as React from 'react';
import styles from './person.module.css';
import Img, { FluidObject } from 'gatsby-image';
import clsx from 'clsx';

interface Props {
  name: string;
  title: string;
  ava?: FluidObject;
  customClass?: string;
}

function Person({ name, title, ava, customClass }: Props) {
  return (
    <div className={clsx(styles.container, customClass)}>
      {ava && (
        <div className={styles.image}>
          <Img fluid={ava} alt={name} className={styles.ava} alt="photo" />
        </div>
      )}
      <div>
        <p className={styles.name}>{name}</p>
        <p className={styles.title}>{title}</p>
      </div>
    </div>
  );
}

export default Person;
